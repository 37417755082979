/* jshint ignore:start */
//Google analytics block from Google
(function (i, s, o, g, r, a, m) {
    var currentDate = new Date();
    i["GoogleAnalyticsObject"] = r; i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * currentDate; a = s.createElement(o),
    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g;
    m.parentNode.insertBefore(a, m);
})(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga", {}, {});
/* jshint ignore:end */
